.heading ul {
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;

    li {
        background-color: #fff;
        border: 1px solid #efefef;

        &:hover,
        &.active {
            background-color: #efefef;

            a {
                color: #000;
            }
        }
    }

    a {
        display: block;
        color: #000;
        text-decoration: none;
        text-transform: uppercase;
        padding: 1em 2em;
    }
}